import React, { useEffect, useState } from 'react';
import './App.scss';
import gif from './byFuncom.gif';
import fire from './fire.gif';
import fire2 from './fire2.gif';

function App() {
    const [data, setData] = useState([]); // Initialiser `data` avec un tableau vide
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // Utiliser correctement `error`

    useEffect(() => {
        fetch('https://api.dune-league.top/') // https://api.dune-league.top/ http://localhost:51005
            .then(response => { 
                if (!response.ok) { 
                    throw new Error('Network response was not ok'); // Lancer une erreur si la réponse n'est pas correcte
                } 
                return response.json();
            })
            .then(data => { 
                setData(data); 
                setLoading(false); 
            })
            .catch(error => { 
                console.log(error); 
                setError(error); // Mettre à jour `error` en cas d'erreur
                setLoading(false); 
            });
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error.message}</p>; // Afficher le message d'erreur dans l'interface utilisateur

    return (
        <div className="App">
            <header className="App-header"></header>
            <div className="App-content">
                <img className='App-content-img' src={gif} alt='gif' />
                <br />
                <a href='https://discord.gg/ha8fDrgJSD' target='_blank' rel='noopener noreferrer' className='App-content-h1'>
                    <br />
                    <img className='App-content-h1-fire' src={fire} alt='fire gif' width={30} /> 
                    <div className='App-content-h1-text'>DUNE LEAGUE</div> 
                    <img className='App-content-h1-fire' src={fire2} alt='fire gif' width={30} />
                </a>
                <div className='App-content-classement'>
                    {data.length > 0 ? data.map((item, index) => (
                        <p key={index}>{`${index + 1} - ${item.username} - ${item.points}`}</p>
                    )) : <p>No data available</p>}
                </div>
            </div>
            <footer className="App-footer">
                <a href='https://shirogames.com/' target='_blank' rel='noopener noreferrer' className='App-footer-link'>© Shiro Games</a> <div className='App-footer-text'> and </div> <a href='https://hub.shiro-community.com/' target='_blank' rel='noopener noreferrer' className='App-footer-link'>Volunteers</a>
            </footer>
        </div>
    );
}

export default App;
